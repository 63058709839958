<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="ticket">
      <van-image radius="20px" :src="item.scenic_spot.screenshot"></van-image>
      <h4 class="mt-2">{{ item.scenic_spot_name }}</h4>
      <div class="van-hairline--top line"></div>
      <div class="d-flex justify-content-between">
        <div class="left">
          <label><van-icon :name="icons.timer" /> {{ $t('play_time_limit') }}</label>
          <p>{{ $t('hour', 24) }}</p>
        </div>
        <div class="right">
          <label>{{ $t('begin_time') }}</label>
          <p class="start_at">{{ time2datetime(item.start_at * 1000) }}</p>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="left">
          <label><van-icon :name="icons.checkticket" /> {{ $t('ticket_checking_station') }}</label>
          <TicketCheckingStation />
        </div>
      </div>
      <div class="van-hairline--top line"></div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="left">
          <label><van-icon size="20px" :name="icons.logo" /> {{ $t("ticket_code") }}</label>
        </div>
        <div class="ticket_code">
          {{ item.ticket_code }}
        </div>
      </div>
    </div>
  </van-config-provider>
</template>

<script>
import { time2datetime } from '@/utils/util';
import TicketCheckingStation from "@/components/TicketCheckingStation";
export default {
  props: {
    item: {
      type: Object,
      require: true,
      default: () => {
        return { id: 0, scenic_spot_name: '' }
      }
    }
  },
  components: { TicketCheckingStation },
  setup() {
    const themeVars = {
      // tabsBottomBarColor: "#222",
      // buttonPrimaryBackgroundColor: "#222",
      // buttonPrimaryBorderColor: "#222",
      cardThumbSize: "66px",
    };
    const icons = {
      timer: require("@/assets/images/icons/timer.svg"),
      checkticket: require("@/assets/images/icons/checkticket.svg"),
      logo: require("@/assets/images/icons/logo.svg"),
    };

    return { themeVars, icons, time2datetime }
  }
}
</script>

<style lang="less" scoped>
.ticket {
  margin: 20px;
  border-radius: 20px;

  .line {
    margin-top: 10px;
    padding-top: 10px;
  }

  label {
    color: #969799;
  }

  .left {
    p {
      margin-left: 20px;
      margin-bottom: 0;

      .van-button {
        border-radius: 15px;
        min-width: 60px;
      }
    }
  }

  .right {
    .start_at {
      width: 100px;
    }
  }

  .ticket_code {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
  }
}
</style>
