<template>
  <van-config-provider :theme-vars="themeVars">
    <van-nav-bar :title="$t('my_tickets')" :left-text="$t('back')" @click.left="$router.back()" />
    <div class="order_wrap">
      <van-tabs v-model:active="activeTab" class="my-2" @change="onRefresh">
        <van-tab :title="$t('valid_tickets')" name="efficient"></van-tab>
        <van-tab :title="$t('expired_tickets')" name="expired"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('no_more')" class="list"
          @load="loadTicketOrder">
          <template v-for="(item, index) in list" :key="index">
            <van-card :thumb="item.scenic_spot.screenshot">
              <template #title>
                <h6>{{ item.scenic_spot_name }}</h6>
              </template>
              <template #desc>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="left">
                    <van-tag v-if="item.used_at" mark plain>{{ $t("used") }}</van-tag>
                    <van-tag v-else mark plain type="success">{{ $t("unused") }}</van-tag>
                    <div class="text-muted">{{ time2date(item.created_at * 1000) }}</div>
                  </div>
                  <van-button size="small" type="primary" @click="showTicket(item)">{{ $t("check") }}</van-button>
                </div>
              </template>
            </van-card>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
  </van-config-provider>
  <van-popup v-model:show="showDetail" round :style="{ width: '90%', height: 'auto' }">
    <TicketDetail :item="currentItem" />
  </van-popup>
</template>

<script>
import { reactive, toRefs } from "vue";
import { orderList } from "@/api/ticket.service";
import { time2date } from "@/utils/util";
import TicketDetail from "./TicketDetail.vue";
export default {
  components: {
    TicketDetail,
  },
  setup() {
    const currentItem = reactive({});
    const state = reactive({
      page: 1,
      page_size: 10,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      keyword: "",
      activeTab: "efficient",
      showDetail: false,
    });

    const themeVars = {
      // tabsBottomBarColor: "#222",
      // buttonPrimaryBackgroundColor: "#222",
      // buttonPrimaryBorderColor: "#222",
      cardThumbSize: "66px",
    };

    const loadTicketOrder = async () => {
      try {
        const { result } = await orderList({
          role: "buyer",
          expiry_status: state.activeTab == "efficient" ? 'no' : 'yes',
          page: state.page,
          page_size: state.page_size,
        });
        if (result.items.length > 0) {
          state.list.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length < state.page_size) {
          state.finished = true;
        }
        state.refreshing = false;
        state.loading = false;
      } catch (error) {
        console.log(error);
      }
    };

    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
      state.list = [];
      state.page = 1;
      loadTicketOrder();
    };

    const showTicket = (item) => {
      state.showDetail = true;
      Object.assign(currentItem, item);
    };

    return {
      ...toRefs(state),
      themeVars,
      currentItem,
      time2date,
      loadTicketOrder,
      onRefresh,
      showTicket,
    };
  },
};
</script>
<style lang="less" scoped>
.order_wrap {
  width: 100%;
  min-height: 100vh;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 0 20px;

  .list {
    min-height: 100vh;
    padding: 0;

    .van-card {
      border-radius: 15px;
      padding: 15px 10px;

      .van-button {
        border-radius: 15px;
        min-width: 60px;
      }
    }
  }
}
</style>
